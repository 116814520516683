@use "~styles/_styleGuideColour" as colours;
@use "../componentStyles" as styles;

.socialShareBarWrapper {
  position: fixed;
  width: 100%;
  background-color: colours.$white;
  bottom: 0;
  z-index: 10;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: styles.$bowShadow;
  left: 0;
}