@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

#oosLabelText {
    text-transform: uppercase;
    color: colours.$red;
}

.primaryTextLabel {
    padding-right: 16px;

    @extend %london1;
}

.looksProductTextLabel {
    @extend %london3;

    padding: 0;
}
