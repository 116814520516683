@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

$buttonPadding: 0 16px;
$buttonBorderWidth: 2px;

%primaryPurchaseButton {
  background-color: colours.$green;
  color: colours.$white;
  border: none;
  max-width: 510px;

  &:hover, &:disabled {
    background-color: colours.$darkGreen;
  }
}

%secondaryPurchaseButton {
  background-color: colours.$white;
  border: $buttonBorderWidth solid colours.$green;

  &:hover {
    border-color: colours.$darkGreen;
  }

  &:disabled {
    background-color: colours.$grey20;
    border-color: colours.$grey20;
    color: colours.$white;
  }
}

%primaryButton {
  background-color: colours.$black;
  color: colours.$white;
  border: none;

  &:hover {
    background-color: colours.$fullBlack;
  }

  &:disabled {
    background-color: colours.$grey40;
  }
}

%secondaryButton {
  background-color: colours.$white;
  border: $buttonBorderWidth solid colours.$grey40;

  &:hover {
    background-color: colours.$grey20;
  }

  &:disabled {
    background-color: colours.$white;
    color: colours.$grey40;
  }
}

%tertiaryButton {
  background-color: colours.$grey20;
  border: none;

  &:hover {
    background-color: colours.$grey40;
  }

  &:disabled {
    background-color: colours.$grey10;
  }
}

%tag {
  background-color: colours.$grey20;
  border-radius: 15px;
  border: none;
  padding: 9px 12px;
  display: inline-block;
  cursor: pointer;

  &:not(:first-of-type) {
    margin-left: 16px;
  }

  &:hover {
    background-color: colours.$grey40;
  }
}

%tagSelected {
  background-color: colours.$black;

  &:hover {
    background-color: colours.$black;
  }
}

%tagValueSelected {
  color: colours.$white;
}

%tagValue {
  @extend %london4;

  text-transform: uppercase;
  margin: 0;
}

%tagInput {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
}

%tagLabel {
  @extend %london3;

  color: colours.$grey70;
  text-transform: uppercase;
  margin-right: 16px;
  display: inline-block;
}

%button {
  font-family: inherit;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed
  }
}

%extraSmallButton {
  @extend %london3-button;
  @extend %button;

  min-height: 28px;
  padding: 0 8px;
}

%smallButton {
  @extend %london3-button;
  @extend %button;

  min-height: 32px;
  padding: $buttonPadding;
}

%mediumButton {
  @extend %london2-button;
  @extend %button;

  min-height: 44px;
  padding: $buttonPadding;
}

%largeButton {
  @extend %london2-button;
  @extend %button;

  min-height: 48px;
  padding: $buttonPadding;
}

%extraLargeButton {
  @extend %london2-button;
  @extend %button;

  min-height: 56px;
  padding: $buttonPadding;
}

%primaryPurchaseButtonExtraSmall {
  @extend %extraSmallButton;
  @extend %primaryPurchaseButton;
}

%primaryPurchaseButtonSmall {
  @extend %smallButton;
  @extend %primaryPurchaseButton;
}

%primaryPurchaseButtonMedium {
  @extend %mediumButton;
  @extend %primaryPurchaseButton;
}

%primaryPurchaseButtonLarge {
  @extend %largeButton;
  @extend %primaryPurchaseButton;
}

%primaryPurchaseButtonExtraLarge {
  @extend %extraLargeButton;
  @extend %primaryPurchaseButton;
}

%secondaryPurchaseButtonExtraSmall {
  @extend %extraSmallButton;
  @extend %secondaryPurchaseButton;
}

%secondaryPurchaseButtonSmall {
  @extend %smallButton;
  @extend %secondaryPurchaseButton;
}

%secondaryPurchaseButtonMedium {
  @extend %mediumButton;
  @extend %secondaryPurchaseButton;
}

%secondaryPurchaseButtonLarge {
  @extend %largeButton;
  @extend %secondaryPurchaseButton;
}

%secondaryPurchaseButtonExtraLarge {
  @extend %extraLargeButton;
  @extend %secondaryPurchaseButton;
}

%primaryButtonExtraSmall {
  @extend %extraSmallButton;
  @extend %primaryButton;
}

%primaryButtonSmall {
  @extend %smallButton;
  @extend %primaryButton;
}

%primaryButtonMedium {
  @extend %mediumButton;
  @extend %primaryButton;
}

%primaryButtonLarge {
  @extend %largeButton;
  @extend %primaryButton;
}

%primaryButtonExtraLarge {
  @extend %extraLargeButton;
  @extend %primaryButton;
}

%secondaryButtonExtraSmall {
  @extend %extraSmallButton;
  @extend %secondaryButton;
}

%secondaryButtonSmall {
  @extend %smallButton;
  @extend %secondaryButton;
}

%secondaryButtonMedium {
  @extend %mediumButton;
  @extend %secondaryButton;
}

%secondaryButtonLarge {
  @extend %largeButton;
  @extend %secondaryButton;
}

%secondaryButtonExtraLarge {
  @extend %extraLargeButton;
  @extend %secondaryButton;
}

%tertiaryButtonExtraSmall {
  @extend %extraSmallButton;
  @extend %tertiaryButton;
}

%tertiaryButtonSmall {
  @extend %smallButton;
  @extend %tertiaryButton;
}

%tertiaryButtonMedium {
  @extend %mediumButton;
  @extend %tertiaryButton;
}

%tertiaryButtonLarge {
  @extend %largeButton;
  @extend %tertiaryButton;
}

%tertiaryButtonExtraLarge {
  @extend %extraLargeButton;
  @extend %tertiaryButton;
}

%linkButton {
  border: none;
  text-decoration: underline;
  background: none;
  padding: 0;
  cursor: pointer;
  font-family: inherit;
}