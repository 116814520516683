@use "~styles/_styleGuideColour" as colours;
@use "../componentStyles" as styles;

.socialShareBoxWrapper {
  position: absolute;
  display: inline-block;
  background-color: #fff;
  top: 32px;
  right: 0;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 13px 70px 3px rgb(45 45 45 / 60%);
  z-index: 10;

  &::before {
    position: absolute;
    display: block;
    content: "";
    height: 10px;
    width: 10px;
    background-color: #FFF;
    right: 4px;
    transform: rotate(45deg);
    top: -5px;
    z-index: 10;
  }
}