@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;

.errorMessage {
  @extend %leavesden3;

  padding: 12px;
  display: inline-block;
  background-color: colours.$lightRed;
  width: 100%;
  box-sizing: border-box;
}