@use "~styles/_styleGuideFont";
@use "~styles/_loadingAnimation";
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_componentStyles" as styles;

.rating {
  width: 100%;
}

.button {
  cursor: pointer;
  display: block;
}

.title {
  @extend %london4;

  width: 100%;
  text-transform: uppercase;
  margin: 0;
  word-wrap: break-word;
}

.headerSpace {
  margin-top: 16px;
}

.contentSpace {
  margin-bottom: 8px;
}

.starsAndRecencyContainer {
  display: flex;
  justify-content: space-between;
}

.recency {
  @extend %leavesden4;

  color: colours.$grey70;
  align-self: center;
}

.source {
  @extend %leavesden4;

  color: colours.$grey70;
  margin-top: 6px;
}

.thumbnails {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.thumbnail {
  @extend %loadingAnimation;

  display: inline-flex;
  width: 100px;
  height: 100px;
  align-items: center;
  overflow: hidden;
  position: relative;
  margin-right: 8px;
  margin-top: 8px;
  border: 0;
  cursor: pointer;
  outline-offset: 2px;
}

.thumbnailImage {
  margin: 0 auto;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}