@use "../componentStyles" as styles;
@use "../viewport" as viewports;

.grid {
  display: flex;
  justify-content: center;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px 8px;
  max-width: viewports.$breakpointMediumScreen;
  width: 100%;
  list-style-type: none;
}

.listItem {
  max-width: 100%;
}

.grid-looks-desktop {
  .list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: viewports.$breakpointSmallScreen) {
  .list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: viewports.$breakpointSmallMediumScreen) {
  .list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: viewports.$breakpointMediumScreen) {
  .list {
    max-width: unset;
    grid-column-gap: 12px;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .grid {
    display: unset;
  }
}
