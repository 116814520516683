@use "~styles/_styleGuideColour" as colours;
@use "~styles/_lowerSectionStyles";
@use "../viewport" as viewports;
@use "~styles/_styleGuideFont";

.fullwidth_container {
  @extend %sectionContainer;

  &.section-grid-looks-desktop {
    border-top: 8px solid colours.$grey20;
  }
}

.container {
  @extend %subContainer;
}

.container-grid {
  padding: 24px 16px;
}

.container-grid-looks-mobile, .container-grid-looks-desktop {
  padding: 16px;
}

.header {
  @extend %header;

  flex-wrap: wrap;

  a { 
    flex-basis: 100%;
  }
}

.hasItemCount {
  display: flex;
  justify-content: space-between;
}

.header-grid-looks-mobile, .header-grid-looks-desktop {
  margin-bottom: 12px;
}

.header-grid-looks-desktop {
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    text-align: center
  }
}

.title {
  @extend %title;
}

.itemCount {
  @extend %leavesden3;
}
