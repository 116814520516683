@use "~styles/_styleGuideButton";
@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;
@use "../../viewport" as viewports;

// This component does not conform to the current style guide

.button {
  @extend %button;
  @extend %london3-button;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: colours.$grey10;
  padding: 20px 0;
  cursor: pointer;
}

.icon {
  display: inline-block;
  margin-right: 5px;
}

@media (min-width: viewports.$breakpointMediumScreen) {
  .button {
    display: none;
  }
}
