@use "~styles/_styleGuideFont";

.title {
  @extend %london3;

  text-transform: uppercase;
  margin-bottom: 6px;
}

.subRating {
  @extend %leavesden3;

  &:not(:first-of-type) {
    margin-top: 16px;
  }
}

.titleRow {
  display: flex;
  align-items: center;
}

.subRatingTitle {
  @extend %london4;

  text-transform: uppercase;
  display: inline-block;
}

.icon {
  display: inline-block;
  margin-inline-start: -9px;
}
