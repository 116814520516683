@use "~styles/_componentStyles" as styles;
@use "~styles/_styleGuideFont";

.colourValue {
  display: inline-block;
}

.leavesden2 {
  @extend %leavesden2;
}

.leavesden3 {
  @extend %leavesden3;
}

.productColour {
  & .colourTitle {
    @extend %london4;

    text-transform: uppercase;
    display: inline-block;
    margin-right: 6px;
  }
}


.withinCarouselFacets {
  margin-top: 0;
  padding: 0 10px;
  box-sizing: border-box;
}