@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

$sliderHeight: var(--ratingBarHeight, 12px);
$sliderWidth: var(--ratingBarWidth, 38px);

.ratingBar {
  height: $sliderHeight;
  width: calc(100% - #{$sliderWidth});
  background-color: var(--ratingBarBackgroundColor, colours.$grey20);
  position: relative;
  margin-bottom: 8px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 100%;
    width: $sliderWidth;
    height: $sliderHeight;
    background-color: inherit;
  }
}

.ratingProgress {
  height: $sliderHeight;
  width: $sliderWidth;
  position: absolute;
  background-color: colours.$black;
}

.ratingsLabels {
  display: flex;

  > div {
    flex: 1;
  }
}

.ratingsLabelMid {
  text-align: center;
}

.ratingsLabelMax {
  text-align: right;
}
