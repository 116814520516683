@use "../viewport" as viewports;
@use "~styles/_styleGuideColour" as colours;

.buttonText {
  text-align: center;
  width: calc(100% - 48px);

  &.iconShown {
    margin-left: 8px;
  }

  &.loadingSpinner {
    width: unset;
  }
}

.disableButtonForLoyalty {
  &:disabled {
    background-color: colours.$lightGreen;
  }
}

@media (min-width: viewports.$breakpointMobileMaxWidth) and (width <= 850px ) {
  .buttonText {
    font-size:14px;
    letter-spacing:0.8px;
    line-height:20px;
  }
}