@use "~styles/_styleGuideColour" as colours;

.section {
  > div {
    border-top-width: 0;
  }

  &.largeViewport {
    border-bottom: 1px solid colours.$grey20;
    margin-bottom: 40px;
  }
}
