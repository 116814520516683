@use "~styles/_styleGuideButton";
@use "~styles/_styleGuideFont";
@use "~styles/_componentStyles" as styles;
@use "~styles/_styleGuideColour" as colours;
@use "../viewport" as viewports;
@use "~styles/_lowerSectionStyles";

.sectionContainer {
  @extend %sectionContainer;
}

.maxWidthContainer {
  @extend %maxWidthContainer;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.title {
  @extend %subContainer;
  @extend %title;

  margin-bottom: 0;
  flex-basis: 100%;
  padding-bottom: 0;
  padding-right: styles.$elementHorizontalSpacing;
}

.subContainer {
  width: 100%;
}

.recommended {
  @extend %leavesden2;

  margin-top: 8px;
}

.mostRecentReviewTitle {
  @extend %london3;

  text-transform: uppercase;
  margin-bottom: 20px;
}

.customerReviews:not(:empty),
.customerRatings {
  @extend %subContainer;

  vertical-align: top;
  padding-right: styles.$elementHorizontalSpacing;
}

.customerRatings {
  padding-bottom: 16px;
}

.customerReviews:not(:empty) {
  border-top: 1px solid colours.$grey20;
}

.viewAllReviews {
  @extend %secondaryButtonMedium;

  width: 100%;
  margin-top: 20px;
}

@media (min-width: viewports.$breakpointMediumScreen) {
  .subContainer {
    width: 50%;
    max-width: styles.$sideModalMaxWidth;
  }

  .customerReviews,
  .customerRatings {
    display: inline-block;
  }

  .customerReviews {
    border: none;

    &:not(:empty) {
      border: none;
    }
  }
}
