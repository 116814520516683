@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;

$columnGap: 14px;
$rowGap: 16px;
$rowHeight: 22px;
$rowButtonHeight: $rowGap + $rowHeight;

.container {
  position: relative;
  padding-top: 14px;
}

.distributionContainer {
  @extend %leavesden2;

  display: grid;
  padding: 20px 16px;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: repeat(5, $rowHeight);
  gap: $rowGap $columnGap;
  align-items: center;
  border-top: 1px colours.$grey20 solid;
}

.ratingBar {
  background-color: colours.$grey20;
  height: 14px;
}

.count {
  justify-self: end;
}

.progress {
  background-color: colours.$black;
  height: 100%;
}

.selectContainer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.rating {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  position: absolute; 
  height: $rowButtonHeight;
  width: 100%;

  &:disabled {
    cursor: default;
  }
}

.ratingFocus {
  &:hover, &:focus {
    background-color: colours.$white;
    opacity: 0.35;
  }
}

@for $i from 0 through 4 {
  .rating:nth-child(#{$i + 1}) {
    top: #{$i * $rowButtonHeight + 28px};
  }
}
