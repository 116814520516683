@use "~styles/_styleGuideColour" as colours;

.spinner, .spinnerLogo {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinnerLogo {
  z-index: 10003;
}

.spinner {
  height: 56px;
  width: 56px;
}

.black.spinner::after {
  background-color: colours.$white;
}

.white.spinner::after {
  background-color: colours.$black;
}