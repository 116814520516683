@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

.header {
  background-color: colours.$grey10;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  margin-right: 24px;

  &.smallViewport {
    margin-right: 16px;
  }
}

.title {
  @extend %barnsley5;

  margin-bottom: 8px;

  &.smallViewport {
    @extend %london1;

    margin-bottom: 4px;
  }
}

.message {
  @extend %leavesden1;

  &.smallViewport {
    @extend %leavesden2;

    margin-bottom: 4px;
  }
}
