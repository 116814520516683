@use "~styles/styleGuideColour" as colours;
@use "~styles/loadingAnimation";

.skeletonTile {
  max-width: 100%;
}

.skeletonImage {
  @extend %loadingAnimation;

  background-color: colours.$grey20;
  box-sizing: border-box;
  margin: 3px;
  position: relative;

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 127.2%;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.skeletonPrice {
  @extend %loadingAnimation;

  background-color: colours.$grey20;
  height: 20px;
  margin: 8px 3px;
  width: 80%;
}

.skeletonBrand {
  @extend %loadingAnimation;

  background-color: colours.$grey20;
  height: 20px;
  margin: 0 3px;
  width: 45%
}