// Ensure the children's focus styles are visible when the parent has 'overflow: hidden' and 'padding: 0'
%overflowHiddenWithFocusShadowPadding {
  overflow: hidden;
  padding: 3px;
  margin: -3px;
}

@mixin multi-line-ellipsis($lineCount) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lineCount;
  -webkit-box-orient: vertical;
}
