.socialShareBar {
  display: inline-flex;
  width: 100%;
  padding: 12px;
  box-shadow: none;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.listItem {
  height: 44px;
  padding-right: 20px;
}

.listItem:last-of-type {
  padding-right: 0;
}

.link, .icon {
  display: block;
  height: 44px;
  width: 44px;
}