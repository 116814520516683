@use "~styles/_componentStyles" as styles;
@use "~styles/_styleGuideFont";

.sizeValue {
  @extend %leavesden2;

  display: inline-block;
}

.leavesden2 {
  @extend %leavesden2;
}

.leavesden3 {
  @extend %leavesden3;
}

.productSize {
  display: block;
  margin-top: 16px;

  & .sizeTitle {
    @extend %london4;

    text-transform: uppercase;
    display: inline-block;
    margin-right: 6px;
  }
}