@use "~styles/_componentStyles" as styles;
@use "~styles/_styleGuideFont" as *;
@use "~styles/_styleGuideColour" as colours;

#label {
    @extend %london4;

    text-transform: uppercase;
    display: inline-block;
}

.variantSelectorContainer {
    padding-top: 8px;

    select {
        @extend %leavesden2;

        appearance: none;
        text-indent: 1px;
        padding: 7px 10% 7px 8px;
        border-radius: 0;
        width: 100%;
        color: colours.$black;
        border: solid 1px colours.$grey90;
        outline: none;
        background: #fff url("../../../images/colourSelectArrow.png") no-repeat right 12px center;
        font-family: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    select:focus {
        outline: none;
        box-shadow: 0 0 3px 1px colours.$blue;
    }
}

:global {
    #variantSelector:required {
        border: solid 2px colours.$red;
    }
}

.sizingHelp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
