@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;

.percentageDiscount {
  @extend %leavesden3;

  margin-left: 4px;
  color: colours.$grey90;
}

.highlight {
  color: colours.$red;
}

.complementary {
  @extend %leavesden3;
}
