@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

.isLowInStock {
  @extend %london3;

  align-items: center;
  display: flex;
  margin-top: 16px;
}

.lowInStockMessage {
  padding-left: 7px;
}

.icon {
  min-width: 20px;
}

.isLooks {
  @extend %london5;

  background-color: colours.$lightAmber;
  border-radius: 12px;
  padding: 4px 8px;
}