@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_lowerSectionStyles";

.section {
  background-color: colours.$grey20;
  padding: 24px 16px;

  &:not(:last-of-type) {
    padding-bottom: 16px;
  }
}

.container {
  background-color: colours.$white;
  filter: drop-shadow(0 0 4px colours.$grey40);
  padding: 16px;
}

.title {
  @extend %london1;

  padding-bottom: 4px;
  text-transform: uppercase;
}

.subtitle {
  @extend %leavesden3;

  color: colours.$grey90;
  margin-bottom: 16px;
}

.image {
  width: 100%;
}

.button {
  @extend %fontFamily;

  border: none;
  text-align: left;
  cursor: pointer;
  -webkit-appearance: none;	
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  margin: 0;
}

.tagContainer {
  margin-bottom: 4px;
}
