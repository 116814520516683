:global {
  // use global style on the id until we get rid of KO and can make it a class
  #social-share-button {
    all: unset;
    cursor: pointer;
  }
}

.socialShareWrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.icon {
  display: block;
}
