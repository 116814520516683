@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: 48px;
  margin-right: 48px;
  text-align: center;

  & > * {
    display: inline-block;
  }
}

.faceEmoji {
  margin-bottom: 24px;
}

.title {
  @extend %barnsley5;

  margin-bottom: 12px;
  text-transform: uppercase;
}

.text {
  @extend %leavesden2;

  margin-bottom: 24px;
}

.button {
  padding: 12px;
  width: 75%;
}
