@use "~styles/styleGuideColour" as colours;
@use "~styles/_viewport.scss" as viewports;

.container {
  border-top: 1px solid colours.$grey20;
  padding: 16px;

  @media(min-width: viewports.$breakpointMediumScreen) {
    padding: 16px 0;
    margin: 0 16px;
  }
}
