@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;

.link {
  @extend %leavesden4;

  color: colours.$grey90;
  display: inline-block;
  padding-top: 8px;
  text-decoration: none;

  span {
    display: inline-block;
    margin-left: 8px;
  }
}