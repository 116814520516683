.sellerInfoButton {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  padding: 0;
  outline: 0;
  background-color: rgb(0 0 0 / 0%);
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  cursor: pointer;
  vertical-align: middle;

  & > span {
    margin-top: -5px;
    margin-left: -1px;
    transform: scale(0.7);
    display: inline-block;
    flex-shrink: 0;
  }
}
