@use "~styles/_styleGuideFont";

.overallRating {
  @extend %leavesden3;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.numericRating {
  margin: 0 8px;
}

.large {
  justify-content: center;

  .numericRating {
    @extend %barnsley5;

    margin: 0 14px;
  }
}
