@use "~styles/_styleGuideFont";

.overallRatingButton {
  @extend %leavesden3;

  font-family: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
}
