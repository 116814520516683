@use "~styles/_viewport" as viewports;
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideButton";
@use "~styles/_componentStyles" as styles;

.modal:not(:empty) {
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  background-color: colours.$white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10001;

  &.center {
    bottom: 0;
    left: 0;
    margin: auto;
    display: none;

    &.open {
      display: block;
    }
  }

  &.side {
    margin-top: 0;
    transform: translateX(100%);
    transition: transform 0.5s linear, visibility 0.5s linear;
    animation-name: slideOpen;
    animation-duration: 0.5s;
    visibility: hidden;
    display: flex;
    flex-direction: column;

    &.open {
      transform: translateX(0);
      visibility: visible;
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: colours.$black;
  opacity: 0;

  &.side {
    transition: opacity 0.5s linear;
    animation-name: fadeIn;
  }

  &.open {
    opacity: 0.7;
  }
}

.stickyContainer {
  position: sticky;
  z-index: 10003;
  top: 0;
}

.modalHeader {
  background-color: colours.$grey10;
  hyphens: auto;
  text-align: center;
  word-break: break-word;
}

.title {
  @extend %barnsley5;

  padding: 20px 53px 0;

  &:last-child {
    padding-bottom: 20px;
  }
}

.subtitle {
  @extend %leavesden2;

  padding: 8px 53px 20px;
}

.close {
  @extend %button;

  background-color: transparent;
  border: none;
  height: 44px;
  padding: 0;
  position: absolute;
  right: 9px;
  top: 12px;
  width: 44px;
  z-index: 10002;
}

.icon {
  &::before,
  &::after {
    background-color: colours.$black;
    content: "";
    height: 2px;
    left: 50%;
    margin-left: -10px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: 20px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.stopScroll {
  overflow-y: hidden;
}

@media (prefers-color-scheme: dark) {
  .withDarkThemeSupport {
    .icon {
      &::before,
      &::after {
        background-color: colours.$grey40;
      }
    }
  }
}

@media (min-width: viewports.$breakpointMediumScreen) {
  .modal {
    &.center {
      max-width: styles.$centerModalMaxWidth;
      max-height: styles.$centerModalMaxHeight;

      &.large {
        max-width: styles.$centerModalLargeMaxWidth;
        max-height: styles.$centerModalLargeMaxHeight;
      }
    }

    &.side {
      max-width: styles.$sideModalMaxWidth;
    }
  }
}

@keyframes slideOpen {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}
