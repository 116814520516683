@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_lowerSectionStyles";

.container {
  @extend %sectionContainer;

  background-color: colours.$grey20;
}

.subContainer {
  @extend %subContainer;

  padding-top: 0;
}

.header {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  .title {
    @extend %london1;

    padding-bottom: 4px;
    text-transform: uppercase;
    padding-top: 24px; // adding padding to title for scroll to title to nicer with space above
  }

  .subtitle, 
  .itemCount {
    @extend %leavesden2;
  }

  .tagContainer {
    display: inline-block;
    left: 6px;
    position: relative;
    top: -3px;
  }
}

.product {
  display: flex;

  .heroImage {
    height: 100%;
    box-shadow: 0 0 12px 0 rgba(colours.$black, 0.2);
    margin-right: 20px;
  }

  .productAside {
    background-color: colours.$white;
    width: calc(100% - 390px); // hero image width + 20px margin
  }
}
