@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";
@import "~styles/_utils";


.title {
  @extend %leavesden4;
  @include multi-line-ellipsis(2);

  padding-top: 4px;
}