@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;
@use "./componentStyles" as styles;
@use "./viewport" as viewports;

%sectionContainer {
  border-top: 8px solid colours.$grey20;

  @media (min-width: viewports.$breakpointMediumScreen) {
    border-top-width: 1px;
  }
}

%maxWidthContainer {
  @media (min-width: viewports.$breakpointLargeScreen) {
    margin: 0 auto;
    max-width: viewports.$containerMaxLargeScreen;
  }
}

%subContainer {
  @extend %maxWidthContainer;

  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: styles.$elementVerticalSpacing 0 styles.$elementVerticalSpacing
    styles.$elementHorizontalSpacing;

  @media (min-width: viewports.$breakpointMediumScreen) {
    margin: 0 auto;
    padding: styles.$elementVerticalSpacing styles.$elementHorizontalSpacing;
  }

  @media (min-width: viewports.$breakpointLargeScreen) {
    padding-right: 0;
    padding-left: 0;
  }
}

%title {
  @extend %london1;

  text-transform: uppercase;

  @media (min-width: viewports.$breakpointMediumScreen) {
    margin-left: 0;
  }
}

%header {
  margin-bottom: styles.$elementVerticalSpacing;
}
