@use "~styles/_styleGuideColour" as colours;

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.large-spinner {
  height: 88px;
  width: 88px;
}

.medium-spinner {
  height: 56px;
  width: 56px;
}

.small-spinner {
  height: 32px;
  width: 32px;
}

.inline-spinner {
  height: 20px;
  width: 20px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.spinner {
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;	
    left: 0;	
    right: 0;
    border-radius: 50%;
    background-repeat: no-repeat;
  }

  &::before {
    animation: rotate 1.5s linear infinite;
    background-image: linear-gradient(0deg, colours.$white, colours.$grey40), linear-gradient(90deg, colours.$grey40, colours.$grey20), linear-gradient(180deg, colours.$grey20, colours.$grey90), linear-gradient(360deg, colours.$grey90, colours.$black);
    background-position: top left, top right, bottom right, bottom left;
    background-size: 50% 50%;
  }

  &::after {
    background-size: 43% 43%;
    background-position: 48% 48%;
    margin: 8%;
  }
}

.spinnerDefaultBackground {
  &::after {
    background-color: colours.$white;
  }
}

