@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont" as fonts;
@use "../componentStyles" as styles;
@use "../viewport" as viewports;

.container {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid colours.$grey20;
  margin-top: 18px;
}

.row:not(:empty) {
  @extend %leavesden3;

  padding: 12px 16px 0;
  display: flex;
  align-items: center;

  &.noIcon {
    margin-left: 36px;
  }

  &.terms {
    padding-bottom: 16px;
  }
}

.shippingLabel:not(:empty) {
  padding: 8px 16px;
  border-bottom: 1px solid rgb(238 238 238);
}

.deliveryMessage {
  padding: 16px 16px 0;
}

.iconContainer {
  display: flex;
  justify-content: center;
  min-width: 36px;
  box-sizing: border-box;
  padding-right: 12px;
}

.icon {
  display: inline-block;
}

.termsLinkText {
  @extend %leavesden3;

  text-decoration: underline;

  > span {
    margin-left: 4px;
  }
}

.shippingRestrictions:not(:empty) {
  @extend %leavesden4;

  padding: 12px 16px 16px;
  border-top: 1px solid rgb(238 238 238);

  & > button {
    font-family: "futura-pt-n7", futura-pt, Tahoma, Geneva, Verdana, Arial,
      sans-serif;
    padding: 0;
    background-color: transparent;
    border: 0;
    line-height: 1.7;
    letter-spacing: 0.8px;
    color: colours.$grey90;
    font-size: 12px;
    cursor: pointer;
    text-align: left;
    text-decoration: underline;
  }
}
