.container {
  width: 102px;
}

.starRating {
  margin: 0;
  padding: 0;
  height: 20px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}

.star {
  position: relative;
  display: block;
  margin-right: 8px;
}

.containerLarge {
  width: 114px;
  margin-left: 24px;

  .starRating {
    transform: scale(1.43);
  }

  .star {
    margin-right: 6px;
  }
}

.star:last-of-type {
  margin-right: 0;
}
