@use "~styles/_styleGuideFont" as *;
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_viewport" as viewports;

.repeatedTextBanner {
  @extend %london4;

  align-items: center;
  color: colours.$white;
  display: none;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  padding: 4px 8px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  &.partOfProductGallery {
    display: block;
  }

  &.access-view {
    background-color: colours.$fullBlack;
  }

  &.access-purchase {
    background-color: colours.$asosWorldPurpleMain;
  }

  .bannerText {
    position: relative;
    left: -50px;
  }
}

@media (min-width: viewports.$breakpointSmallMediumScreen) {
  .repeatedTextBanner {
    display: block;
    margin: -24px 0 20px;

    &.partOfProductGallery {
      display: none;
    }
  }
}
