@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideButton";

.pill {
  @extend %tag;

  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 100px;
  background-color: colours.$white;
  position: relative;
  margin: 14px;
}

.pillButton {
  font-family: inherit;
  background-color: transparent;
  min-height: 44px;
  min-width: 44px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.pillButton:hover .pill {
  background-color: colours.$grey40;
}

.content {
  @extend %london4;

  text-transform: uppercase;
}
