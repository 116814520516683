.pricingButton {
    padding: 8px;
    margin-block: -8px;
    outline: 0;
    background-color: rgb(0 0 0 / 0%);
    border-width: 0;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    cursor: pointer;
}

.icon {
    margin: -5px;
    transform: scale(0.7);
    display: block;
}
