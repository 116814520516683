@use "~styles/viewport" as viewports;

.button {
  min-height: 44px;
}

.withSaveButton {
  width: calc(100% - 56px);
}

.button:not(.withSaveButton) {
  width: 100%;
}

.icon {
  position: relative;
  left: 14px;
}

.text {
  flex: 1;
  padding: 4px 30px;
}


@media (min-width: viewports.$breakpointMediumScreen) {
  .desktopLooks {
    min-height: 32px;
  }
}
