@use "~styles/_styleGuideColour" as colours;

.saveCountWrapper{
    position: absolute;
    right: 0;
    bottom: 38px;
    z-index: 2;
    text-transform: uppercase;
    height: 28px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: colours.$black;
}

.saveCountvalue {
    color: colours.$white;
    font-size: 14px;
    font-weight: 900;
    padding: 0 9px 0 16px;
    line-height: 28px;
    letter-spacing: .8px;
    user-select: text;
}

.saveCountHeartIcon {
    display: inline-block;
    padding-right: 16px;
    vertical-align: middle;
    margin-top: -4px;
}