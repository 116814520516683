@use "~styles/_styleGuideButton";
@use "~styles/_componentStyles" as styles;
@use "~styles/_lowerSectionStyles";

.container {
  @extend %sectionContainer;
}

.subContainer {
  @extend %subContainer;
}

.title {
  @extend %title;
}

.header {
  margin-bottom: styles.$elementVerticalSpacing - 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.description {
  display: none;
}

.clearButton {
  @extend %tertiaryButtonSmall;

  display: inline-block;
  margin-right: styles.$elementHorizontalSpacing;
}
