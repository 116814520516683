@use "~styles/_componentStyles" as styles;
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

.container {
  @extend %leavesden2;

  padding: styles.$elementVerticalSpacing;
  display: flex;
  background-color: colours.$lightRed;
}

.infoIcon {
  display: inline-block;
  margin-right: styles.$elementHorizontalSpacing;
  flex-shrink: 0;
}
