@use "~styles/_lowerSectionStyles";
@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;
@use "~styles//viewport" as viewports;

.container {
  border-top: 1px solid colours.$grey20;
}

.subContainer {
  @extend %subContainer;

  padding: 20px 16px;

  @media(min-width: viewports.$breakpointLargeScreen) {
    padding: 24px 10px;
  }
}

.title {
  @extend %london3;

  text-transform: uppercase;
  color: colours.$grey70;
  margin-bottom: 4px;
}

.content, .link {
  @extend %leavesden2;
}

.warningIcon {
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 7px;
}

.warningText {
  text-transform: uppercase;
}
