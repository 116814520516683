@use "~styles/_viewport" as viewports;

.galleryButton {
  > * {
    flex-grow: 1;
  }
}

.icon {
  display: inline-block;
  margin-right: 12px;
  vertical-align: top;
}

@media (min-width: viewports.$breakpointMediumScreen) {
  .galleryButton {
    margin: 0 auto;
    flex-direction: column;
  }

  .icon {
    margin: unset;
  }

  .text {
    margin-top: 13px;

    // font-size 11 and associated styles are not defined as part of the design system in _styleGuideFont.scss
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 1px;
  }
}
