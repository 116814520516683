@use "~styles/_styleGuideColour" as colours;

@mixin font-family {font-family: "futura-pt-n4",futura-pt,Tahoma,Geneva,Verdana,Arial,sans-serif;}
%fontFamily {
    @include font-family
}

@mixin barnsley1 {font-size:60px;font-weight:bold;letter-spacing:1.5px;line-height:72px;color:colours.$black;}
%barnsley1 {
    @include barnsley1
}

@mixin barnsley2 {font-size:48px;font-weight:bold;letter-spacing:1.2px;line-height:56px;color:colours.$black;}
%barnsley2 {
    @include barnsley2
}

@mixin barnsley3 {font-size:36px;font-weight:bold;letter-spacing:1.2px;line-height:44px;color:colours.$black;}
%barnsley3 {
    @include barnsley3
}

@mixin barnsley4 {font-size:28px;font-weight:bold;letter-spacing:1.2px;line-height:36px;color:colours.$black;}
%barnsley4 {
    @include barnsley4
}

@mixin barnsley5 {font-size:24px;font-weight:bold;letter-spacing:1.2px;line-height:30px;color:colours.$black;}
%barnsley5 {
    @include barnsley5
}

@mixin london2-button {font-size:16px;font-weight:bold;letter-spacing:0.6px;line-height:18px;color:colours.$black;}
%london2-button {
    @include london2-button
}

@mixin london3-button {font-size:14px;font-weight:bold;letter-spacing:0.8px;line-height:16px;color:colours.$black;}
%london3-button {
    @include london3-button
}

@mixin london1 {font-size:18px;font-weight:bold;letter-spacing:0.8px;line-height:24px;color:colours.$black;}
%london1 {
    @include london1
}

@mixin london2 {font-size:16px;font-weight:bold;letter-spacing:0.6px;line-height:22px;color:colours.$black;}
%london2 {
    @include london2
}

@mixin london3 {font-size:14px;font-weight:bold;letter-spacing:0.8px;line-height:20px;color:colours.$black;}
%london3 {
    @include london3
}

@mixin london4 {font-size:12px;font-weight:bold;letter-spacing:1px;line-height:16px;color:colours.$black;}
%london4 {
    @include london4
}

@mixin london5 {font-size:10px;font-weight:bold;letter-spacing:1.2px;line-height:14px;color:colours.$black;}
%london5 {
    @include london5
}

@mixin leavesden1 {font-size:18px;font-weight:normal;letter-spacing:0.6px;line-height:24px;color:colours.$black;}
%leavesden1 {
    @include leavesden1
}

@mixin leavesden2 {font-size:16px;font-weight:normal;letter-spacing:0.4px;line-height:22px;color:colours.$black;}
%leavesden2 {
    @include leavesden2
}

@mixin leavesden3 {font-size:14px;font-weight:normal;letter-spacing:0.6px;line-height:20px;color:colours.$black;}
%leavesden3 {
    @include leavesden3
}

@mixin leavesden4 {font-size:12px;font-weight:normal;letter-spacing:0.8px;line-height:16px;color:colours.$black;}
%leavesden4 {
    @include leavesden4
}