@use "~styles/_styleGuideColour" as colours;
@use "~styles/_componentStyles" as styles;
@use "../viewport" as viewports;
@use "~styles/_styleGuideButton";

.toast {
  position: sticky;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 16px 16px 0;
  background-color: colours.$lightRed;
  box-sizing: border-box;
  z-index: 100000;
}

.icon {
  display: inline-block;
  margin-right: 16px;
  vertical-align: top;
}

.content {
  display: inline-block;
  max-width: 80%;
}

.closeButton {
  @extend %button;

  border: none;
  background-color: transparent;
  position: absolute;
  height: 44px;
  width: 44px;
  top: 0;
  right: 0;
}

.closeIcon {
  margin: 0 auto;
}

@media(min-width: viewports.$breakpointMediumScreen) {
  .toast {
    max-width: styles.$sideModalMaxWidth;
  }
}
