@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;

.similarItemsFailure {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0 18px;
  text-align: center;
  border-top: 8px solid colours.$grey20;
}

.faceEmoji {
  margin-bottom: 24px;
}

.title {
  @extend %london1;

  margin-bottom: 12px;
  text-transform: uppercase;
}

.text {
  @extend %leavesden2;

  margin: 0 18px 24px;
}
