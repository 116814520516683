@use "~styles/_styleGuideColour" as colours;

$product-tile-image-aspect-ratio: 87 / 111;
$product-image-height-to-width-percent: calc((1 / ($product-tile-image-aspect-ratio)) * 100%);
$save-for-later-size: 44px;
$save-for-later-gutter: 8px;
$save-for-later-padding: 4px;
$bottom-right-button-offset: calc($save-for-later-gutter + $save-for-later-size);


:root {
  --translateX: 0;
  --translateY: 0;
  --distanceFirstTop: 0;
  --distanceSecondTop: 0;
  --distanceThirdTop: 0;
  --distanceFirstLeft: 0;
  --distanceSecondLeft: 0;
  --distanceThirdLeft: 0;
}

.saveForLater {
  border: none;
  border-radius: 100px;
  height: $save-for-later-size;
  width: $save-for-later-size;
  position: absolute;
  top: 0;
  right: 7px;
  margin-top: calc($product-image-height-to-width-percent - $bottom-right-button-offset);
  background-color: rgb(255 255 255 / 70%);
  background-clip: content-box;
  padding: $save-for-later-padding;
  cursor: pointer;
  overflow: visible;

  &.singleProductSaveForLater,
  &.mixAndMatchSaveForLater {
    background-color: colours.$grey20;
    bottom: 2px;
    right: 0;
    margin-top: 0;
    background-clip: border-box;
  }

  &.mixAndMatchSaveForLater {
    background-clip: content-box;
    padding: 2px;
    position: relative;
    right: unset;
  }

  &.buyTheLookSaveForLater {
    right: $save-for-later-gutter - $save-for-later-padding;
    margin-top:  calc($product-image-height-to-width-percent - $bottom-right-button-offset + $save-for-later-padding + 2px);
  }
}

.icon {
  position: absolute;
  display: block;
  margin-top: 1px;
  left: 13px;
  top: 14px;

 .singleProductSaveForLater &, 
 .mixAndMatchSaveForLater & {
    left: 13px;
    top: 13px;
  }
}

.animation {
  animation-name: heartBeat;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.flutters {
  position: absolute;
  width: 18px;
  height: 16px;
  margin-top: 1px;
  left: 9px;
  top: 9px;

  .mixAndMatchSaveForLater & {
    left: 11px;
    top: 11px;
  }
}

.flutterHeart {
  position: absolute;
  transition: top ease-in 0.5s, left ease-in 0.5s, opacity linear 0.5s;
  opacity: 0;
  top: 0;
  left: 0;
  margin-top: 0;
}

.flutterAnimation {
  animation-name: heartFlutter;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes heartFlutter {
  0% {
    top: 0;
    left: 0;
    opacity: 0.6;
  }

  33% {
    top: calc(var(--translateY) * var(--distanceFirstTop));
    left: calc(var(--translateX) * var(--distanceFirstLeft));
    transform: scale(0.8);
    opacity: 0.6;
  }

  66% {
    top: calc(var(--translateY) * var(--distanceSecondTop));
    left: calc(var(--translateX) * var(--distanceSecondLeft));
    transform: scale(0.7);
    opacity: 0.6;
  }

  90% {
    opacity: 0.4;
  }

  100% {
    top: calc(var(--translateY) * var(--distanceThirdTop));
    left: calc(var(--translateX) * var(--distanceThirdLeft));
    transform: scale(0.5);
    opacity: 0;
  }
}

