@use "~styles/_styleGuideColour" as colours;

@keyframes loading {
  0% {
    background-color: colours.$grey20;
  }

  25% {
    background-color: colours.$grey40;
  }

  50% {
    background-color: colours.$grey20;
  }

  75% {
    background-color: colours.$grey10;
  }

  100% {
    background-color: colours.$grey20;
  }
}

%loadingAnimation {
  animation-name: loading;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: 13;
}