@use "~styles/_styleGuideFont";
@use "~styles/_loadingAnimation";
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_componentStyles" as styles;

.headerSpace {
  margin-top: 16px;
}

.contentSpace {
  margin-bottom: 8px;
}

.text {
  width: 100%;
  font-family: inherit;
  overflow: hidden;
  position: relative;
  border: none;
  background-color: transparent;
  text-align: left;
  padding: 0;
  word-wrap: break-word;
}

.paragraphText {
  @extend %leavesden3;

  max-height: 60px;
}

.disclaimerText {
  @extend %leavesden4;

  color: colours.$grey90;
  max-height: 32px;
}

.button {
  cursor: pointer;
  display: block;
}

.expanded {
  max-height: 100%;
  cursor: default;
}

.showMoreContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.readMore {
  display: inline-block;
  cursor: pointer;
  color: inherit;
  background-color: colours.$white;
  border: none;
  text-align: right;
  text-decoration: underline;
}

.fade {
  display: inline-block;
  width: 30px;
  background-image: linear-gradient(to right, rgb(255 255 255 / 0%), rgb(255 255 255 / 100%));
  vertical-align: bottom;
}

.paragraphFade {
  height: 20px;
}

.disclaimerFade {
  height: 16px;
}
