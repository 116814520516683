@use "~styles/_styleGuideColour" as colours;
@use "~styles/viewport" as viewports;
@use "~styles/_styleGuideFont";

.primaryPrice {
  @extend %london1;

  color: colours.$grey90;
}

.secondaryPrice,
.gridPrice {
  @extend %london3;

  color: colours.$grey90;
}

.isSalePrice {
  color: colours.$red;
}

.complementaryPrimaryPrice {
  @extend %london2;

  &.isSalePrice {
    color: colours.$red;
  }
}

@media (min-width: viewports.$breakpointMediumScreen) {
  .gridPrice:not(.isSalePrice) {
    color: colours.$black;
  }
}
