@use "~styles/_styleGuideColour" as colours;
@use "~styles/_lowerSectionStyles";

.container {
  @extend %subContainer;
}

.header {
  @extend %header;
}

.title {
  @extend %title;
}

.btl_full_width_container {
  background-color: colours.$grey20;
}

.tileWrapper {
  background-color: colours.$white;
  box-sizing: border-box;
  height: 100%;
  padding: 17px;
}