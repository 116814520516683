.mixAndMatchPaymentPromoContainer:not(:empty) {
    display: block;
    clear: both;
    margin-top: 8px;
}

@media (width >= 721px) {
    .mixAndMatchPaymentPromoContainer:not(:empty) {
        margin-top: 12px;
    }
}
