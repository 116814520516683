@use "~styles/_styleGuideFont" as fonts;
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideButton";

.review {
  padding: 20px 14px;
  border-bottom: 1px solid colours.$grey20;
}

.viewMoreContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: colours.$grey20;
  flex-grow: 1;
}

.reviewsCount {
  @extend %leavesden3;

  margin-bottom: 10px;
  color: colours.$grey90;
}

.reviewsProgress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 1px;
  width: 65%;
  height: 2px;
  margin-bottom: 20px;
  background-color: colours.$grey40;
  color: colours.$blue;
  
  &::-webkit-progress-bar {
    background-color: colours.$grey40;
    border-radius: 1px;
  }

  &::-webkit-progress-value {
    background-color: colours.$blue;
    border-radius: 1px;
  }

  &::-moz-progress-bar {
    background-color: colours.$blue;
    border-radius: 1px;
  }

  &::-ms-fill {
    background-color: colours.$blue;
    border: none;
  }
}

.viewMore {
  @extend %secondaryButtonMedium;

  width: 100%;
}

.spinner {
  &::after {
    background-color: colours.$grey20;
  }
}
