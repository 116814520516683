.advertContainer {
  display: flex;
}

.advertContainer:not(:empty) {
  padding: 32px 0;
}

@media (width >= 720px) {
  .advertContainer:not(:empty) {
    padding: 36px 0;
  }
}
