@use "~styles/_styleGuideButton";
@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideColour" as colours;

.button {
  @extend %button;

  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    box-shadow: 0 0 3px 1px colours.$blue;
  }
}

.london2 {
  @extend %london2;
}

.london3 {
  @extend %london3;
}

.leavesden3 {
  @extend %leavesden3;
}

.primaryPurchase{
  @extend %primaryPurchaseButton;

  width: calc(100% - 56px);
  min-height: 44px;
  padding: 4px 0;
}

.primaryPurchaseFullWidth {
  @extend %primaryPurchaseButton;

  width: 100%;
  min-height: 44px;
  padding: 4px 0;
}

.secondaryPurchase{
  @extend %secondaryPurchaseButton; 
}

.secondaryPurchaseFullWidth {
  @extend %secondaryPurchaseButton;
  @extend %london3;

  width: 100%;
  min-height: 32px;
  padding: 4px 0;
}

.primary{
  @extend %primaryButton; 
}

.secondary{
  @extend %secondaryButton; 
}

.tertiary{
  @extend %tertiaryButton; 
}

.naked {
  border: none;
  background-color: transparent;
  padding: 0;
}
