@use "~styles/_styleGuideButton";
@use "~styles/_styleGuideColour" as colours;

.fitAssistantButton {
  @extend %linkButton;
  @extend %leavesden3;

  display: flex;
  align-items: center;
  text-align: left;
  margin-left: 8px;
}

.iconContainer {
  display: inline-block;
  margin-right: 8px;
  border-radius: 9px;
  background-color: colours.$fitAssistantBlue;
  min-height: 18px;
  min-width: 18px;
}

.icon {
  margin: 3px 0 0 3px;
}
