@use "~styles/_styleGuideColour" as colours;
@use "~styles/_componentStyles" as styles;
@use "~styles/_styleGuideButton";

.apiErrorMessage{
  @extend %leavesden2;
}

@keyframes show {
  from {
      transform: translateY(100px);
  }

  to {
      transform: translateY(0);
  }
}

.notificationDrawer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 100000;
  animation-duration: 1s;
  animation-name: show;
}

.notificationDrawer button div{
  margin-right:10px;
}

.notificationDrawer button{
  margin-right:-7px;
}

.notificationDrawer button:focus {
  box-shadow: 0 0 3px 1px colours.$blue;
}