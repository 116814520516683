@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont" as fonts;
@use "../../componentStyles" as styles;

.shippingLabel {
  color: colours.$grey90;

  b {
    font-weight: bold;
  }
}

.leavesden3 {
  @extend %leavesden3;
}

.leavesden4 {
  @extend %leavesden4;
}
