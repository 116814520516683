@use "~styles/_styleGuideFont";
@use "../viewport" as viewports;

.paymentPromoComponent {
  @extend %leavesden3;

  display: block;
}

.logo {
  display: inline-block;
  margin-top: 0;
  position: relative;
}

.afterpay-logo {
  @extend .logo;

  top: 4px;
  left: -1px;
}

.klarna-logo {
  @extend .logo;

  top: 1px;
}

.paypalpayin3-logo {
  @extend .logo;

  top: 2px;
}

.paypalpayin4-logo {
  @extend .logo;

  top: 5px;
}

.paypalpaylater-logo {
  @extend .logo;

  top: 5px;
}

.clearpay-logo {
  @extend .logo;

  top: 4px;
}

.arvato-logo {
  @extend .logo;

  top: 4px;
  left: 2px;
}

.riverty-logo-info {
  @extend .logo;

  margin-left: 4px;
  top: 4px;
}

.clearpay-info-logo {
  @extend .logo;

  top: 4px;
}
