@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont";

.primaryPrice {
  @extend %leavesden3;

  color: colours.$grey90;
}

.secondaryPrice, .gridPrice {
  @extend %london3;

  color: colours.$grey90;
  font-weight: normal;
  text-decoration: line-through;
}

.secondaryPrice {
  margin-right: 4px;
}

.gridPrice {
  display: block;
}

.complementaryPrimaryPrice {
  @extend %leavesden3;

  color: colours.$grey90;
}
