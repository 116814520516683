@use "~styles/_styleGuideFont";
@use "~styles/_styleGuideButton";
@use "~styles/_componentStyles" as styles;
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_viewport" as viewports;

.container {
  padding: styles.$elementVerticalSpacing styles.$elementHorizontalSpacing;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.label {
  @extend %london3;

  margin-bottom: 6px;
  color: colours.$grey70 !important;
}

.countryList,
.country {
  list-style: disc;
}

.countryList {
  padding: 20px 20px 0;
  width: 100%;
}

.country {
  @extend %leavesden2;

  width: 249px;
}

.filterInput {
  @extend %leavesden2;

  padding: 9px 14px;
  border: solid 1px colours.$black;
  width: 100%;
}

.noMatches {
  @extend %leavesden2;

  width: 100%;
  padding-top: 20px;
}

.description {
  @extend %london3;

  width: 100%;
  padding-top: 20px;
}
