@use "~styles/_styleGuideColour" as colours;

.container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10002;
}

.clear {
  background-color: rgba(colours.$white, 0.7);
}

.dark {
  background-color: rgba(colours.$black, 1);
  z-index: 10005;
}
