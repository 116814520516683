@use "~styles/_loadingAnimation";
@use "../componentStyles" as styles;
@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideFont" as *;

.container {
  position: relative;
  width: 100%;
  padding: 3px;
  box-sizing: border-box;
}

.link {
  display: block;
  text-decoration: none;
  width: 100%;
}

.skeleton {
  @extend %loadingAnimation;

  background-color: colours.$grey20;
  position: relative;

  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 127.2%;
  }

  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.image {
  cursor: pointer;
  display: block;
  width: 100%;
}

.closeButton {
  position: absolute;
  top: 8px;
  left: 8px;
}

.closeIcon {
  display: block;
  position: absolute;
  top: 11.5px;
  left: 11.5px;
}
