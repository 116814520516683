$black: #2d2d2d;
$fullBlack: #000;
$grey90: #666;
$grey70: #767676;
$grey40: #ddd;
$grey20: #eee;
$grey10: #f8f8f8;
$white: #fff;
$green: #018849;
$darkGreen: #006637;
$lightGreen: #cceede;
$amber: #ff9c32;
$darkAmber: #b6752f;
$lightAmber: #ffebcc;
$blue: #0770cf;
$darkBlue: #1862a5;
$lightBlue: #cde2f5;
$red: #d01345;
$lightRed: #fae7ec;
$brightRed: #ff3b30;
$fitAssistantBlue: #060e89;
$asosWorldPurpleMain: #6f5cff;
$colours: (
  "black": $black,
  "fullBlack": $fullBlack,
  "grey90": $grey90,
  "grey70": $grey70,
  "grey40": $grey40,
  "grey20": $grey20,
  "grey10": $grey10,
  "white": $white,
  "green": $green,
  "darkGreen": $darkGreen,
  "lightGreen": $lightGreen,
  "amber": $amber,
  "darkAmber": $darkAmber,
  "lightAmber": $lightAmber,
  "blue": $blue,
  "darkBlue": $darkBlue,
  "lightBlue": $lightBlue,
  "red": $red,
  "lightRed": $lightRed,
  "brightRed": $brightRed,
  "fitAssistantBlue": $fitAssistantBlue,
  "asosWorldPurpleMain": $asosWorldPurpleMain,
);
