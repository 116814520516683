@use "~styles/styleGuideColour" as colours;
@use "~styles/styleGuideFont";
@use "~styles/viewport" as viewports;
@use "~styles/loadingAnimation";
@import "~styles/_utils";

.container {
  display: flex;
  align-items: flex-start;
}

.imageContainer {
  position: relative;
}

.aside {
  padding-left: 16px;
  width: 100%;
}

.title {
  @extend %leavesden3;
  @include multi-line-ellipsis(3);
}

.link {
  text-decoration: unset;
  display: block;
}

.imageLink {
  @extend %loadingAnimation;

  display: block;
  width: 163px;
  height: 208.3px;
}

@media (min-width: viewports.$breakpointLargeScreen) {
  .imageLink {
    width: 218px;
    height: 279px;
  }

  .smallImageOnly {
    width: 163px;
    height: 208.3px;
  }
}
