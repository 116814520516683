@use "~styles/_styleGuideColour" as colours;

.container {
  height: 100%;
  overflow: hidden;
}

.backBtn {
  border: 0;
  background: transparent;
  position: absolute;
  z-index: 2;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 10004;
}

.image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  flex-shrink: 0;
}

.previousButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.paginationButtons {
  background-color: transparent;
  min-height: 32px;
  min-width: 32px;
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationButtonsContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 36px;
  left: 0;
  width: 100%;
}

.galleryContainer {
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 10004;

  &.animated {
    transition: margin 0.5s;
  }
}

.chevron {
  background-color: transparent;
  min-height: 44px;
  min-width: 44px;
  border: none;
  cursor: pointer;
  position: absolute;
}

.circleButtons {
  background-color: colours.$white;
  height: 12px;
  width: 12px;
  border: 1px solid colours.$grey70;
  border-radius: 50%;
  padding: 0;

  &.selected {
    background-color: colours.$grey90;
  }
}