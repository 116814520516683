@use "~styles/_styleGuideColour" as colours;

.faceEmoji {
  width: 88px;
  height: 88px;
  position: relative;
}

.faceEmoji > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.face {
  fill: #f9c20b; // outlier, not in the style guide
}

.features {
  fill: colours.$black;
  color: colours.$white;
}
