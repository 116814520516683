@use "~styles/_viewport" as viewports;
@use "~styles/_loadingAnimation";
@use "~styles/_styleGuideColour" as colours;

.image {
  @extend %loadingAnimation;

  height: 71.5px;
  width: 56px;
}

.carousel {
  display: flex;
  flex-direction: row;
  justify-items: left;
  max-width: calc(100% - 32px);
  min-height: 77px;
  overflow-x: auto;
  padding: 16px;
  width: 100%;
}

.carouselThumbnailContainer {
  display: flex;
}

.tile {
  border: none;
  background-color: colours.$white;
  display: inline-block;
  margin: 0 4px 0 0;
  padding: 0;

  > :first-child {
    border: 2px solid colours.$white;
    padding: 1px;
  }

  &.selected {
    > :first-child {
      border-color: colours.$black;
    }
  }

  &:focus, 
  &:active {
    > :first-child {
      border-color: colours.$blue;
    }
  }

  &:focus-visible {
    box-shadow: none;
    outline: none;
  }
}

@media (min-width: viewports.$breakpointMediumScreen) {
  .image {
    height: 96.43px;
    width: 75px;
  }

  .carousel {
    min-height: 104px;
    overflow: hidden;
    padding: 0;
    position: relative;
    margin: 16px;
    white-space: nowrap;
  }

  .carouselThumbnailContainer {
    position: absolute;
    transition: right 0.3s ease;
    width: 100%;
  }

  .tile {
    cursor: pointer;

    &:hover {
      > :first-child {
        border-color: colours.$blue;
      }
    }

    &.selected {
      > :first-child {
        border-color: colours.$black;
      }
    }
  }

  .scrollButton {
    cursor: pointer;
    display: block;
    height: 104px;
    position: absolute;
    top: 0;
    width: 44px;
    z-index: 1;

    > .navigationButtons {
      display: block;
      position: relative;
    }

    &.scrollButtonRight {
      background: linear-gradient(90deg, rgba(colours.$white, 0.00) 0%, colours.$white 100%);
      right: 0;
      margin-right: -2px;
    }

    &.scrollButtonLeft {
      background: linear-gradient(270deg, rgba(colours.$white, 0.00) 0%, colours.$white 100%);
      left: 0;
      margin-left: -2px;
    }
  }
}
