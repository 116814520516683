$elementVerticalSpacing: 24px;
$elementHorizontalSpacing: 16px;
$carouselButtonWidth: 79px;
$carouselFocusPadding: 5px;
$sideModalMaxWidth: 375px;
$centerModalMaxWidth: 600px;
$centerModalMaxHeight: 530px;
$centerModalLargeMaxWidth: 900px;
$centerModalLargeMaxHeight: calc(100% - 120px);
$bowShadow: 0 13px 70px 3px rgb(45 45 45 / 60%);
