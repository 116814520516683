@use "~styles/_styleGuideColour" as colours;
@use "~styles/_styleGuideButton";
@use "~styles/_componentStyles" as styles;

.ratingsAndReviewsModal {
  position: relative;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  padding-top: styles.$elementVerticalSpacing;
}

.recommended {
  @extend %leavesden3;

  text-align: center;
}

.subRatings {
  padding: 20px 16px;
  border-top: 1px solid colours.$grey20;
}

.reviews {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.reviewsUnFiltered {
  border-top: 8px solid colours.$grey20;
}

.apiErrorMessage,
.apiErrorButton {
  @extend %leavesden2;
}

.ratingsPillContainer {
  background-color: colours.$grey20;
}

.closeIcon {
  display: block;
  margin: 0 0 0 5px;
}

.apiFilterErrorMessage {
  padding-bottom: 16px;
}

.apiErrorButton {
  @extend %button;

  background-color: transparent;
  font-weight: bold;
  text-transform: none;
  border: none;
  padding: 0;
  min-height: 44px;
  min-width: 44px;
  width: 100%;
  text-align: left;
}
